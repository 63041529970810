const swiperCards = [
    {
        image: "/images/image1.jpg",
        title: "Step 1",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, \nsed do eiusmod  tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
        image: "/images/image2.jpg",
        title: "Step 2",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, \nsed do eiusmod  tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
        image: "/images/image3.jpg",
        title: "Step 3",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, \nsed do eiusmod  tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
        image: "/images/image1.jpg",
        title: "Step 4",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, \nsed do eiusmod  tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
        image: "/images/image2.jpg",
        title: "Step 5",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, \nsed do eiusmod  tempor incididunt ut labore et dolore magna aliqua.",
    },
];

export default swiperCards;
