import logoImg from "assets/img/logo_white.png";

const PARTNERS = [
    {
        src: logoImg,
        alt: "로고1",
    },
    {
        src: logoImg,
        alt: "로고2",
    },
    {
        src: logoImg,
        alt: "로고3",
    },
    {
        src: logoImg,
        alt: "로고4",
    },
    {
        src: logoImg,
        alt: "로고5",
    },
];

export default PARTNERS;
